import React from 'react';

import './Home.css';

const Home = () => {
    return (
        <div id="home" className="Home">
        </div>
    );
}

export default Home;